import { Outlet } from "react-router-dom";
import NavbarTop from "./NavbarTop";

const Layout = () => {
  return (
    <>
      <div className="d-none d-md-block">
        <NavbarTop />
      </div>
      <div className="">
        <div className="">
          <main className="content">
            <div className="">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
