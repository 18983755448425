import React from 'react';

function TenantProfileData({ next }) {




    return (
        <>
            <div className='register-form d-grid gap-3 mt-3'>
                <div className="d-flex form-group">
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("name", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("name")}
                        id="name"
                        placeholder="Vorname"
                        required
                    />
                </div>
                <div className="d-flex form-group">
                    <input
                        type="text"
                        name="familyName"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("familyName", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("familyName")}
                        id="familyName"
                        placeholder="Nachname"
                        required
                    />
                </div>
                <div className="d-flex form-group">
                    <input
                        type="number"
                        name="phoneNumber"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("phoneNumber", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("phoneNumber")}
                        id="phoneNumber"
                        placeholder="Telefonnummer"
                        required
                    />
                </div>
                <div className="d-flex form-group">
                    <input
                        type="number"
                        name="age"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("age", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("age")}
                        id="age"
                        placeholder="Alter"
                        required
                    />
                </div>
            </div>
        </>
    )
}

export default TenantProfileData