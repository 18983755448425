import React from 'react';
import { useNavigate } from 'react-router-dom';

function TenantProfileRent() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>
                    Kaltmiete
                    <div className='d-flex gap-2'>
                        <div>
                            <input
                                type="text"
                                id="coldRentMin"
                                name="coldRentMin"
                                onChange={(e) => { window.sessionStorage.setItem("coldRentMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("coldRentMin")} />
                        </div>
                        <span>-</span>
                        <div>
                            <input
                                type="text"
                                id="coldRentMax"
                                name="coldRentMax"
                                onChange={(e) => { window.sessionStorage.setItem("coldRentMax", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("coldRentMax")} />
                        </div>
                    </div>
                    Nebenkosten
                    <div className='d-flex gap-2'>
                        <div>
                            <input
                                type="text"
                                id="extraCostsMin"
                                name="extraCostsMin"
                                onChange={(e) => { window.sessionStorage.setItem("extraCostsMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("extraCostsMin")} />
                        </div>
                        <span>-</span>
                        <div>
                            <input
                                type="text"
                                id="extraCostsMax"
                                name="extraCostsMax"
                                onChange={(e) => { window.sessionStorage.setItem("extraCostsMax", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("extraCostsMax")} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TenantProfileRent