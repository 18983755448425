import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PorpertyAddress from './PropertyAddress';
import PropertyData from './PropertyData';
import PropertyDetail from './PropertyDetail';
import PropertyExtras from './PropertyExtras';
import PropertyRent from './PropertyRent';

function PropertyMain() {
    const location = useLocation()
    const navigate = useNavigate();
    const [step, setStep] = useState(1)

    const next = () => {
        if (step < 5) {
            setStep((step) => step + 1)
        }
    }

    const back = () => {
        if (step > 0) {
            setStep((step) => step - 1)
        }

        if (location.pathname.includes("register")) {
            navigate("/register", { replace: true })
        }
    }

    const handleInsert = (e) => {
        const input = {
            title: sessionStorage.getItem("property_title") ? sessionStorage.getItem("property_title") : "",
            year: sessionStorage.getItem("property_year") ? sessionStorage.getItem("property_year") : "",
            type: sessionStorage.getItem("property_type") ? sessionStorage.getItem("property_type") : "",
            apartmentType: sessionStorage.getItem("property_apartmentType") && sessionStorage.getItem("property_type") === "apartment" ? sessionStorage.getItem("property_apartmentType") : "",
            street: sessionStorage.getItem("street") ? sessionStorage.getItem("street") : "",
            houseNumber: sessionStorage.getItem("houseNumber") ? sessionStorage.getItem("houseNumber") : "",
            plz: sessionStorage.getItem("property_plz") ? sessionStorage.getItem("property_plz") : "",
            city: sessionStorage.getItem("property_city") ? sessionStorage.getItem("property_city") : "",
            size: sessionStorage.getItem("property_size") ? sessionStorage.getItem("property_size") : "",
            rooms: sessionStorage.getItem("property_rooms") ? sessionStorage.getItem("property_rooms") : "",
            floor: sessionStorage.getItem("property_floor") ? sessionStorage.getItem("property_floor") : "",
            coldRent: sessionStorage.getItem("property_coldRent") ? sessionStorage.getItem("property_coldRent") : "",
            kitchen: sessionStorage.getItem("property_kitchen") ? sessionStorage.getItem("property_kitchen") : false,
            garden: sessionStorage.getItem("property_garden") ? sessionStorage.getItem("property_garden") : false,
            basement: sessionStorage.getItem("property_basement") ? sessionStorage.getItem("property_basement") : false,
            elevator: sessionStorage.getItem("property_elevator") ? sessionStorage.getItem("property_elevator") : false,
            guestBathroom: sessionStorage.getItem("property_guestBathroom") ? sessionStorage.getItem("property_guestBathroom") : false,
            fibre: sessionStorage.getItem("property_fibre") ? sessionStorage.getItem("property_fibre") : false,
            furnished: sessionStorage.getItem("property_furnished") ? sessionStorage.getItem("property_furnished") : false,
            balcony: sessionStorage.getItem("property_balcony") ? sessionStorage.getItem("property_balcony") : false,
        }
    }

    return (
        <div className="container mt-3">
            <h1 className='d-flex justify-content-center'>Immobilie inserieren</h1>
            <div className='pill-navigation'>
                <div className={step === 1 ? "pill-button active" : "pill-button"} onClick={() => setStep(1)}>Daten</div>
                <div className={step === 2 ? "pill-button active" : "pill-button"} onClick={() => setStep(2)}>Objektart</div>
                <div className={step === 3 ? "pill-button active" : "pill-button"} onClick={() => setStep(3)}>Details</div>
                <div className={step === 4 ? "pill-button active" : "pill-button"} onClick={() => setStep(4)}>Extras</div>
                <div className={step === 5 ? "pill-button active" : "pill-button"} onClick={() => setStep(5)}>Miete</div>
            </div>
            {step === 1 ?
                <PropertyData /> : <></>
            }
            {step === 2 ?
                <PorpertyAddress /> : <></>
            }
            {step === 3 ?
                <PropertyDetail /> : <></>
            }
            {step === 4 ?
                <PropertyExtras /> : <></>
            }
            {step === 5 ?
                <PropertyRent /> : <></>
            }


            {step < 5 ? <div className="d-grid gap-2">
                <button type='button' className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }} onClick={next}>Weiter</button>
            </div> : <div className="d-grid gap-2">
                <button type='submit' className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }} onClick={handleInsert}>Immobilie inserieren</button>
            </div>}
            {step > 0 ?
                <div className="d-grid gap-2">
                    <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
                </div> : <></>
            }
            {step === 0 && location.pathname.includes("register") ?
                <div className="d-grid gap-2">
                    <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
                </div> : <></>
            }
        </div>
    )
}

export default PropertyMain