import React from 'react';
import { useNavigate } from 'react-router-dom';

function PorpertyAddress() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>

                    <div className='d-flex gap-2'>
                        <div className='d-grid'>
                            <label for="city">Stadt</label>
                            <input type="text"
                                id="city"
                                name="city"
                                onChange={(e) => { window.sessionStorage.setItem("city", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("city")}
                            />
                        </div>
                        <div className='d-grid'>
                            <label for="city">Plz</label>
                            <input type="text"
                                id="plz"
                                name="plz"
                                onChange={(e) => { window.sessionStorage.setItem("plz", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("plz")}
                            />
                        </div>
                    </div>
                    <div className='d-flex gap-2'>
                        <div className='d-grid'>
                            <label for="city">Straße</label>
                            <input type="text"
                                id="street"
                                name="street"
                                onChange={(e) => { window.sessionStorage.setItem("street", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("street")}
                            />
                        </div>
                        <div className='d-grid'>
                            <label for="city">Hausnummer</label>
                            <input type="text"
                                id="houseNumber"
                                name="houseNumber"
                                onChange={(e) => { window.sessionStorage.setItem("houseNumber", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("houseNumber")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PorpertyAddress