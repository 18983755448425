import React from 'react';
import { useNavigate } from 'react-router-dom';

function TenantProfileExtras() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-flex flex-wrap justify-content-start gap-3'>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='kitchen'
                            onClick={(e) => window.sessionStorage.setItem("kitchen", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("kitchen") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Einbauküche</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='garden'
                            onClick={(e) => window.sessionStorage.setItem("garden", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("garden") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Garten</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='basement'
                            onClick={(e) => window.sessionStorage.setItem("basement", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("basement") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Keller</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='elevator'
                            onClick={(e) => window.sessionStorage.setItem("elevator", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("elevator") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Fahrstuhl</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='guestBathroom'
                            onClick={(e) => window.sessionStorage.setItem("guestBathroom", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("guestBathroom") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Gäste Wc</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='fibre'
                            onClick={(e) => window.sessionStorage.setItem("fibre", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("fibre") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Glasfaser</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='furnished'
                            onClick={(e) => window.sessionStorage.setItem("furnished", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("furnished") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Möbliert</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='balcony'
                            onClick={(e) => window.sessionStorage.setItem("balcony", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("balcony") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Balkon</span>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TenantProfileExtras