import * as React from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import TenantProfileMain from "../Profile/Tenant/TenantProfileMain";
import RegisterMain from "./RegisterMain";
import LandlordProfileMain from "../Profile/Landlord/LandlordProfileMain";
const REGISTER_URL = "/auth/signup";

const Register = () => {

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false)
  const [selection, setSelection] = useState(1)
  const [createProfile, setCreateProfile] = useState(false)

  const next = () => {
    setCreateProfile(!createProfile)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);

      const user = Object.fromEntries(formData);

      if (user.password === user.passwordConfirm) {
        await axios.put(REGISTER_URL, user);
        setSuccess(true)
      } else {
        alert("Passwords do not match")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>

      <div className="container-fluid d-grid">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>Willkommen bei Meine Bude</p>
        </div>
        <div className="container mt-3">
          {success ?
            <div>Aktivierungslink wurde an die Email Adresse geschickt</div>
            :
            <>
              {createProfile === false ?
                <RegisterMain selection={selection} setSelection={setSelection} /> : <></>
              }
              {createProfile === true && selection === 1 ?
                <TenantProfileMain /> : <></>
              }
              {createProfile === true && selection === 2 ?
                <LandlordProfileMain /> : <></>
              }
            </>
          }
          {!createProfile ?
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }} onClick={next}>Weiter</button>
            </div> : <></>
          }
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-secondary mt-3"
              onClick={() => navigate("/")}
              style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }}
            >
              Zum Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
