import React from 'react';

function TenantProfileType() {

    return (
        <>

            <div className='d-grid gap-3 mt-3'>
                Art
                <div className='d-flex flex-wrap gap-2'>
                    <div>
                        <input type="checkbox"
                            id="apartment"
                            name="apartment"
                            onClick={(e) => window.sessionStorage.setItem("apartment", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("apartment") === "true" ? true : false} />
                        <label for="apartment">&nbsp;Wohnung</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="house"
                            name="house"
                            onClick={(e) => window.sessionStorage.setItem("house", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("house") === "true" ? true : false} />
                        <label for="house">&nbsp;Haus</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="rowHouse"
                            name="rowHouse"
                            onClick={(e) => window.sessionStorage.setItem("rowHouse", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("rowHouse") === "true" ? true : false} />
                        <label for="rowHouse">&nbsp;Reihenhaus</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="semiDetachedHouse"
                            name="semiDetachedHouse"
                            onClick={(e) => window.sessionStorage.setItem("semiDetachedHouse", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("semiDetachedHouse") === "true" ? true : false} />
                        <label for="semiDetachedHouse">&nbsp;Doppelhaushälfte</label>
                    </div>

                </div>
                Wohnungstyp
                <div className='d-flex flex-wrap gap-2'>
                    <div>
                        <input type="checkbox"
                            id="souterrain"
                            name="souterrain"
                            onClick={(e) => window.sessionStorage.setItem("souterrain", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("souterrain") === "true" ? true : false} />
                        <label for="souterrain">&nbsp;Souterrain</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="groundFloor"
                            name="groundFloor"
                            onClick={(e) => window.sessionStorage.setItem("groundFloor", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("groundFloor") === "true" ? true : false} />
                        <label for="groundFloor">&nbsp;Erdgeschoss</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="atticFloor"
                            name="atticFloor"
                            onClick={(e) => window.sessionStorage.setItem("atticFloor", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("atticFloor") === "true" ? true : false} />
                        <label for="atticFloor">&nbsp;Dachgeschoss</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="maisonette"
                            name="maisonette"
                            onClick={(e) => window.sessionStorage.setItem("maisonette", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("maisonette") === "true" ? true : false} />
                        <label for="maisonette">&nbsp;Maisonette</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="loft"
                            name="loft"
                            onClick={(e) => window.sessionStorage.setItem("loft", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("loft") === "true" ? true : false} />
                        <label for="loft">&nbsp;Loft</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="penthouse"
                            name="penthouse"
                            onClick={(e) => window.sessionStorage.setItem("penthouse", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("penthouse") === "true" ? true : false} />
                        <label for="penthouse">&nbsp;Penthouse</label>
                    </div>
                    <div>
                        <input type="checkbox"
                            id="Terrace"
                            name="Terrace"
                            onClick={(e) => window.sessionStorage.setItem("Terrace", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("Terrace") === "true" ? true : false} />
                        <label for="Terrace">&nbsp;Terasse</label>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TenantProfileType