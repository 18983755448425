import React from 'react'

function RegisterFinal() {
    return (<>
        <div className="d-flex form-group mail">
            <input
                type="email"
                name="email"
                className="form-control"
                id="Email1"
                aria-describedby="emailHelp"
                placeholder="E-Mail Adresse"
                required
            />
            <i className="p-3 bi bi-envelope-fill icon"></i>
        </div>
        <div className="d-flex form-group mt-3 password">
            <input
                type="password"
                name="password"
                className="form-control"
                id="Password"
                placeholder="Passwort"
                required
            />
            <i className="p-3 bi bi-key-fill icon"></i>
        </div>
        <div className="d-flex form-group mt-3 password">
            <input
                type="password"
                name="passwordConfirm"
                className="form-control"
                id="PasswordConfirm"
                placeholder="Passwort bestätigen"
                required
            />
            <i className="p-3 bi bi-key-fill icon"></i>
        </div>
    </>
    )
}

export default RegisterFinal