import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query';
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import PropertyMain from "./components/Property/PropertyMain";
import RequireAuth from "./components/RequireAuth";
import "./i18n";
import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import Homepage from "./pages/Homepage/Homepage";

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Layout />}>
          <Route path="*" element={<Missing />} />
          <Route path="" element={<Homepage />} />

          <Route path="login" element={<LandingPage />} />
          <Route path="register" element={<Register />} />
          <Route path="insert" element={<PropertyMain />} />
          <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
            <Route path="" element={<Dashboard />} />

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="admin" element={<Admin />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
