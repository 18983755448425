import React from 'react';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import './Homepage.css'
import cardImage from '../../assets/cardHomepage.svg'
import cologne from '../../assets/cologne.png'
import cardImage2 from '../../assets/HomepageLastsection.png'
import logo from '../../assets/logo.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function HomePage() {

  const slider = React.useRef(null);
  return (
    <Container fluid className='homepage'>
      {/* Hero Section */}
      <Row className="hero-section">
        <Col className="text-center hero-content">
          <h1 className='fw-bold'>Deine Bude findet dich!</h1>
          <p className='fw-bold'>Wie Tinder - nur für Mieter & Vermieter</p>
          <Button variant="primary">Jetzt Traum-Bude finden!</Button>
        </Col>
      </Row>

      {/* Main Section */}
      <Container>
        {/* Features Section */}

        <Row className="py-5">
          <Col>
            <h2>Mit wenigen Klicks zur Traumbude.</h2>
            <p className='sub-text'>Unser intelligentes Matching-System findet die passenden Angebote für dich.</p>
            <p className='sub-text'>Einfach registrieren, Kriterien auswählen und los geht's!</p>
          </Col>
        </Row>
        <Row className="">
          <Col md={4} className='mb-4 mb-md-0'>
            <Card className='h-100 d-flex flex-column'>
              <Card.Body>
                <Card.Title>Finden - nicht suchen</Card.Title>
                <Card.Text>
                  Einfache und gezielte Wohnungssuche.
                </Card.Text>
                <img class="card-img-bottom" src={cardImage} alt="Card image cap" width={100} height={200} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-4 mb-md-0'>
            <Card className='h-100 d-flex flex-column'>
              <Card.Body>

                <Card.Title>Eine Anfrage für alle Wohnungen</Card.Title>
                <Card.Text>
                  Keine einzelnen Anfragen notwendig.
                </Card.Text>
                <img class="card-img-bottom" src={cardImage} alt="Card image cap" width={100} height={200} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-4 mb-md-0'>
            <Card className='h-100 d-flex flex-column'>
              <Card.Body>
                <Card.Title>Kein Hin und Her</Card.Title>
                <Card.Text>
                  Wohnungen mit den besten Chancen sofort entdecken.
                </Card.Text>
                <img class="card-img-bottom" src={cardImage} alt="Card image cap" width={100} height={200} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* How it works Section */}
        <Row className="py-5">
          <Col>
            <h2>Wie funktioniert Meine Bude?</h2>
          </Col>
        </Row>
        <Row className="">
          <Col md={4}>
            <Card className='h-100'>
              <Card.Body className='mb-5 mt-5'>
                <div className='text-center number position-relative top-10 start-50 translate-middle'><p>1</p></div>
                <Card.Title> Profil & Wünsche eingeben</Card.Title>
                <Card.Text>
                  Sie können ihre Wünsche genau festhalten und werden nur auf zutreffende Einladungen erhalte
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className='h-100'>
              <Card.Body className='mb-5 mt-5'>
                <div className='text-center number position-relative top-10 start-50 translate-middle'><p>2</p></div>
                <Card.Title>Vermieter lädt Sie ein</Card.Title>
                <Card.Text>
                  Ihre Wünsche werden mit den Inseraten gematched & Ihr Profil dem Vermieter zum Teil zur Verfügung gestellt. Dieser lädt nur Leute ein, die auch wirklich Chancen haben.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className='h-100'>
              <Card.Body className='mb-5 mt-5'>
                <div className='text-center number position-relative top-10 start-50 translate-middle'><p className='text-center'>3</p></div>
                <Card.Title>Besichtigen & Zusagen</Card.Title>
                <Card.Text>
                  Erst nach Besichtigung werden dem Vermieter sensible Informationen über Ihre Person freigegeben.  So können Sie einfach Ihre Dokumente freigeben, wenn Sie die Zusage haben
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Cities Section */}

      
        <Row className="py-5">
          <Col>
            <h2>Auch in deiner Stadt</h2>
            <p className='sub-text'>Auch in deiner Stadt</p>
          </Col>

          <Col>
            <button onClick={() => slider?.current?.slickPrev()}>{"<"}</button>
            <button onClick={() => slider?.current?.slickNext()}>{">"}</button>
          </Col>
        </Row>

       
        <Row className="">

        <Slider {...settings}  ref={slider}>
          <Col md={4}>
            <div className="card custom-card">
              <img src={cologne} className="card-img-top custom-img" alt="Köln" />
              <div className="card-body">
                <h5 className="card-title">Köln</h5>
                <p className="card-subtitle text-muted">Untertitel</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="card custom-card">
              <img src={cologne} className="card-img-top custom-img" alt="Köln" />
              <div className="card-body">
                <h5 className="card-title">Hamburg</h5>
                <p className="card-subtitle text-muted">Untertitel</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="card custom-card">
              <img src={cologne} className="card-img-top custom-img" alt="Köln" />
              <div className="card-body">
                <h5 className="card-title">Bremen</h5>
                <p className="card-subtitle text-muted">Untertitel</p>
              </div>
            </div>
          </Col>
          </Slider>
        </Row>


       

        {/* What we offer Section */}
        <Row className="py-5">
          <Col>
            <p className='text-break'>Suche und finde deine perfekte Wohnung – so einfach wie nie zuvor.  Ob zum Arbeiten, Studieren oder einfach nur zum Wohlfühlen:  Bei uns findest du flexible Mietlösungen für jeden Anspruch.</p>
          </Col>
        </Row>
        <Row className="">
          <Col md={4}>
            <Card className='h-100'>
            <img src={logo}  alt="image" width={30} height={30} />
              <Card.Body className='mb-5 mt-5'>
       
                <Card.Title> Verifizierte Wohnungen</Card.Title>

                <Card.Text>
                Dank unserer gründlichen Prüfungen kannst du dir sicher sein, dass du deine Zeit nicht mit Besichtigungen von Wohnungen verschwendest, die nicht deinen Erwartungen entsprechen.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className='h-100'>
            <img src={logo}  alt="image" width={30} height={30} />
              <Card.Body className='mb-5 mt-5'>
       
                <Card.Title>Intelligentes Matching</Card.Title>

                <Card.Text>
                Zeit sparen durch intelligentes Matching. Unser System durchsucht ständig unsere Datenbank und schlägt dir Wohnungen vor, die genau auf deine Kriterien passen.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className='h-100'>
            <img src={logo}  alt="image" width={30} height={30} />
              <Card.Body className='mb-5 mt-5'>
            
              
                <Card.Title>Persönlicher Support</Card.Title>

                <Card.Text>
                Unser erfahrenes Team steht dir jederzeit zur Seite. Wir beantworten deine Fragen, unterstützen dich bei der Suche und sind dein persönlicher Ansprechpartner bei allen Anliegen rund um deine neue Wohnung.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

         {/* Last Section */}
       
        <Row className="py-5">
          <Col md={6}>
          <div className="card custom-card">
              <img src={cardImage2} className="card-img-top custom-img" alt="image" />
              <div className="card-body">
                <h5 className="card-title">Jetzt ihre Traumbude vermieten</h5>
                <p className="card-subtitle text-muted">Untertitel</p>
                <p className="card-subtitle text-muted">Mehr erfahren</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
          <div className="card custom-card">
              <img src={cardImage2} className="card-img-top custom-img" alt="image" />
              <div className="card-body">
                <h5 className="card-title">Wohnungsverwaltung: Coming soon</h5>
                <p className="card-subtitle text-muted">Untertitel</p>
                <p className="card-subtitle text-muted">Mehr erfahren</p>

              </div>
            </div>
          </Col>
        
        </Row>

      </Container>
    </Container>
  );
}

export default HomePage;
