import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";
import Unauthorized from "./Unauthorized";

const RequireAuth = ({ allowedRoles }) => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();    

    useEffect(() => {
        const refreshToken = async () => {
            if (!auth?.accessToken) {
                const newAccessToken = await refresh();
                if (newAccessToken) {                    
                    setIsLoading(false)
                }
            }
            else {
            }
            setIsLoading(false)
        }

        refreshToken().catch(err => {            
            setIsLoading(false)
        })
    }, [])

    return (
        !auth?.accessToken && isLoading ?
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
            </div>
                : auth?.accessToken && !allowedRoles.includes(auth?.role) ? <Unauthorized /> 
                : auth?.accessToken && allowedRoles.includes(auth?.role) ? <Outlet /> 
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;