import React, { useEffect, useState } from 'react';

function PropertyData({ next }) {

    const [apartmentActive, setApartmentActive] = useState(false)

    useEffect(() => {
        if (sessionStorage.getItem("property_type") === "apartment") {
            setApartmentActive(true)
        }
    }, [])

    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid'>

                    <label for="city">Titel</label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("property_title", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("property_title")}
                        id="title"
                        placeholder="Titel"
                        required
                    />
                </div>
                <div className='d-grid'>

                    <label for="city">Baujahr</label>
                    <input
                        type="text"
                        name="year"
                        className="form-control"
                        onChange={(e) => window.sessionStorage.setItem("property_year", e.target.value)}
                        defaultValue={window.sessionStorage.getItem("property_year")}
                        id="year"
                        placeholder="Baujahr"
                        required
                    />
                </div>
                Art
                <div className='d-flex flex-wrap gap-2'>
                    <div>
                        <input type="radio"
                            id="apartment"
                            name="type"
                            onClick={(e) => {
                                window.sessionStorage.setItem("property_type", "apartment")
                                setApartmentActive(true)
                            }}
                            defaultChecked={window.sessionStorage.getItem("property_type") === "apartment" ? true : false} />
                        <label for="apartment">&nbsp;Wohnung</label>
                    </div>
                    <div>
                        <input type="radio"
                            id="house"
                            name="type"
                            onClick={(e) => {
                                window.sessionStorage.setItem("property_type", "house")
                                setApartmentActive(false)
                            }}
                            defaultChecked={window.sessionStorage.getItem("property_type") === "house" ? true : false} />
                        <label for="house">&nbsp;Haus</label>
                    </div>
                    <div>
                        <input type="radio"
                            id="rowHouse"
                            name="type"
                            onClick={(e) => {
                                window.sessionStorage.setItem("property_type", "rowHouse")
                                setApartmentActive(false)
                            }}
                            defaultChecked={window.sessionStorage.getItem("property_type") === "rowHouse" ? true : false} />
                        <label for="rowHouse">&nbsp;Reihenhaus</label>
                    </div>
                    <div>
                        <input type="radio"
                            id="semiDetachedHouse"
                            name="type"
                            onClick={(e) => {
                                window.sessionStorage.setItem("property_type", "semiDetachedHouse")
                                setApartmentActive(false)
                            }}
                            defaultChecked={window.sessionStorage.getItem("property_type") === "semiDetachedHouse" ? true : false} />
                        <label for="semiDetachedHouse">&nbsp;Doppelhaushälfte</label>
                    </div>
                </div>
                {apartmentActive === true ? <>
                    Wohnungstyp
                    <div className='d-flex flex-wrap gap-2'>
                        <div>
                            <input type="radio"
                                id="souterrain"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "souterrain")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "souterrain" ? true : false} />
                            <label for="souterrain">&nbsp;Souterrain</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="groundFloor"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentTyper", "groundFloor")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentTyper") === "groundFloor" ? true : false} />
                            <label for="groundFloor">&nbsp;Erdgeschoss</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="atticFloor"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "atticFloor")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "atticFloor" ? true : false} />
                            <label for="atticFloor">&nbsp;Dachgeschoss</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="maisonette"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "maisonette")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "maisonette" ? true : false} />
                            <label for="maisonette">&nbsp;Maisonette</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="loft"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "loft")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "loft" ? true : false} />
                            <label for="loft">&nbsp;Loft</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="penthouse"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "penthouse")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "penthouse" ? true : false} />
                            <label for="penthouse">&nbsp;Penthouse</label>
                        </div>
                        <div>
                            <input type="radio"
                                id="terrace"
                                name="apartmentType"
                                onClick={(e) => window.sessionStorage.setItem("property_apartmentType", "terrace")}
                                defaultChecked={window.sessionStorage.getItem("property_apartmentType") === "terrace" ? true : false} />
                            <label for="Terrace">&nbsp;Terasse</label>
                        </div>
                    </div></> : <></>
                }
            </div>
        </>
    )
}

export default PropertyData