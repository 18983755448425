import { useNavigate } from "react-router-dom"


function Admin() {

  const navigate = useNavigate()

  return (
    <>
      <h1>Admin Page</h1>
    </>
  )
}

export default Admin