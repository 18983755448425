import React from 'react';

function PropertyExtras() {

    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-flex flex-wrap justify-content-start gap-3'>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='kitchen'
                            onClick={(e) => window.sessionStorage.setItem("property_kitchen", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_kitchen") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Einbauküche</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='garden'
                            onClick={(e) => window.sessionStorage.setItem("property_garden", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_garden") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Garten</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='basement'
                            onClick={(e) => window.sessionStorage.setItem("property_basement", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_basement") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Keller</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='elevator'
                            onClick={(e) => window.sessionStorage.setItem("property_elevator", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_elevator") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Fahrstuhl</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='guestBathroom'
                            onClick={(e) => window.sessionStorage.setItem("property_guestBathroom", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_guestBathroom") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Gäste Wc</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='fibre'
                            onClick={(e) => window.sessionStorage.setItem("property_fibre", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_fibre") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Glasfaser</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='furnished'
                            onClick={(e) => window.sessionStorage.setItem("property_furnished", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_furnished") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Möbliert</span>
                    </div>
                    <div className='d-grid gap-2 p-2 border'>
                        <input
                            type='checkbox'
                            name='balcony'
                            onClick={(e) => window.sessionStorage.setItem("property_balcony", e.target.checked)}
                            defaultChecked={window.sessionStorage.getItem("property_balcony") === "true" ? true : false} />
                        <img src='img/kueche.jpg' />
                        <span>Balkon</span>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PropertyExtras