import React from 'react'
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import axios from "../api/axios";
const LOGIN_URL = "/auth/signin";

function LandingPage() {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData(e.target);
            const user = Object.fromEntries(formData);
            const response = await axios.post(LOGIN_URL, user, {
                withCredentials: true,
            });

            const accessToken = response?.data?.token;
            const role = response?.data?.role;
            setAuth({ user, accessToken, role });
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }
    };

    return (
        <div className="hero-image">
            <div className="inhalt-hero">
                <div className='mt-3 d-flex justify-content-center'>
                    <h3>Finde jetzt deine Traum-Bude!</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="custom-input">
                        <label for="username">E-Mail </label><br />
                        <input name="email" />
                    </div>
                    <div className="custom-input" style={{ marginTop: "10px" }}>
                        <label for="password">Passwort </label><br />
                        <input name="password" type="password" />
                    </div>
                    <span style={{ color: "red" }}>{errMsg}</span>
                    <div>
                        <button className="button" type="submit">
                            Einloggen
                        </button>
                    </div>
                </form>
                <div className='mt-3 d-flex justify-content-center'>
                    <p>Noch nicht registriert?</p>
                    <Link to={"/register"} style={{ textDecoration: "none", color: "#335DA8" }}>
                        &nbsp;Einen Account erstellen
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LandingPage