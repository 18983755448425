import React from 'react';
import { useNavigate } from 'react-router-dom';

function TenantProfileDetail() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>
                    Nutzfläche ca.
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="sizeMin"
                                name="sizeMin"
                                onChange={(e) => { window.sessionStorage.setItem("sizeMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("sizeMin")}
                            />
                        </div>
                        <span>-</span>
                        <div>
                            <input type="text"
                                id="sizeMax"
                                name="sizeMax"
                                onChange={(e) => { window.sessionStorage.setItem("sizeMax", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("sizeMax")}
                            />
                        </div>
                    </div>
                    Zimmer
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="roomsMin"
                                name="roomsMin"
                                onChange={(e) => { window.sessionStorage.setItem("roomsMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("roomsMin")}
                            />
                        </div>
                        <span>-</span>
                        <div>
                            <input type="text"
                                id="roomsMax"
                                name="roomsMax"
                                onChange={(e) => { window.sessionStorage.setItem("roomsMax", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("roomsMax")}
                            />
                        </div>
                    </div>
                    Etage
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="floorMin"
                                name="floorMin"
                                onChange={(e) => { window.sessionStorage.setItem("floorMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("floorMin")}
                            />
                        </div>
                        <span>-</span>
                        <div>
                            <input type="text"
                                id="floorMax"
                                name="floorMax"
                                onChange={(e) => { window.sessionStorage.setItem("floorMax", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("floorMax")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TenantProfileDetail