import React from 'react';
import { useNavigate } from 'react-router-dom';

function PropertyRent() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>
                    Kaltmiete
                    <div className='d-flex gap-2'>
                        <div>
                            <input
                                type="text"
                                id="coldRent"
                                name="coldRent"
                                onChange={(e) => { window.sessionStorage.setItem("property_coldRent", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("property_coldRent")} />
                        </div>
                    </div>
                    Nebenkosten
                    <div className='d-flex gap-2'>
                        <div>
                            <input
                                type="text"
                                id="extraCosts"
                                name="extraCosts"
                                onChange={(e) => { window.sessionStorage.setItem("property_extraCosts", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("property_extraCosts")} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyRent