import React from 'react';

function TenantProfileWho({ next }) {




    return (
        <>
            <div className='d-flex flex-wrap gap-2'>
                <div>
                    <input type="radio"
                        id="alone"
                        name="type"
                        onClick={(e) => {
                            window.sessionStorage.setItem("who", "alone")
                        }}
                        defaultChecked={window.sessionStorage.getItem("who") === "alone" ? true : false} />
                    <label for="alone">&nbsp;Alleine</label>
                </div>
                <div>
                    <input type="radio"
                        id="livingCommunity"
                        name="type"
                        onClick={(e) => {
                            window.sessionStorage.setItem("who", "livingCommunity")
                        }}
                        defaultChecked={window.sessionStorage.getItem("who") === "livingCommunity" ? true : false} />
                    <label for="livingCommunity">&nbsp;WG</label>
                </div>
                <div>
                    <input type="radio"
                        id="family"
                        name="type"
                        onClick={(e) => {
                            window.sessionStorage.setItem("who", "family")
                        }}
                        defaultChecked={window.sessionStorage.getItem("who") === "family" ? true : false} />
                    <label for="family">&nbsp;Familie</label>
                </div>
            </div>
        </>
    )
}

export default TenantProfileWho