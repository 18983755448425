import React from 'react';

function RegisterMain({ selection, setSelection }) {
    return (
        <>
            <div className="mt-2">
                <div>Ich möchte eine Wohnung:</div>
                <div class="btn-group" style={{ width: "100%" }}>
                    <button className="p-1" type="button" style={{ border: "none", boxShadow: "1px 1px 1px rgb(0 0 0 / 20%)", borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px", width: "50%", backgroundColor: selection === 1 ? "#335DA8" : "#fff", color: selection === 1 ? "#fff" : "#000" }} onClick={() => setSelection(1)}>mieten</button>
                    <button className="p-1" type="button" style={{ border: "none", boxShadow: "1px 1px 1px rgb(0 0 0 / 20%)", borderTopRightRadius: "15px", borderBottomRightRadius: "15px", width: "50%", backgroundColor: selection === 2 ? "#335DA8" : "#fff", color: selection === 2 ? "#fff" : "#000" }} onClick={() => setSelection(2)}>vermieten</button></div>
            </div>

        </>
    )
}

export default RegisterMain