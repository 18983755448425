import React from 'react';

function PropertyDetail() {

    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>
                    Nutzfläche ca.
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="size"
                                name="size"
                                onChange={(e) => { window.sessionStorage.setItem("property_size", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("property_size")}
                            />
                        </div>

                    </div>
                    Zimmer
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="rooms"
                                name="rooms"
                                onChange={(e) => { window.sessionStorage.setItem("property_rooms", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("property_rooms")}
                            />
                        </div>

                    </div>
                    Etage
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="floor"
                                name="floor"
                                onChange={(e) => { window.sessionStorage.setItem("property_floor", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("property_floor")}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetail