import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import RegisterFinal from '../../Auth/RegisterFinal';
import LandlordProfileData from './LandlordProfileData';

function LandlordProfileMain() {
    const location = useLocation()
    const navigate = useNavigate();
    const [step, setStep] = useState(1)
    const axiosPrivate = useAxiosPrivate()

    const next = () => {
        if (step < 2) {
            setStep((step) => step + 1)
        }
    }

    const back = () => {
        if (step > 0) {
            setStep((step) => step - 1)
        }

        if (location.pathname.includes("register")) {
            navigate("/register", { replace: true })
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append("name", sessionStorage.getItem("name"))
        formData.append("familyName", sessionStorage.getItem("familyName"))
        const input = Object.fromEntries(formData)
        const response = await axiosPrivate.post("/auth/registerLandlord", input)
    }
    return (
        <div className="mt-3">
            <div className='pill-navigation'>
                <div className={step === 1 ? "pill-button active" : "pill-button"} onClick={() => setStep(1)}>Daten</div>
                {/* <div className={step === 2 ? "pill-button active" : "pill-button"} onClick={() => setStep(2)}>Objektart</div> */}
                <div className={step === 2 ? "pill-button active" : "pill-button"} onClick={() => setStep(2)}>Email</div>
            </div>
            <form onSubmit={handleRegister}>
                {step === 1 ?
                    <LandlordProfileData /> : <></>
                }
                {/* {step === 2 ?
                    <LandlordProfileDetail /> : <></>
                } */}
                {step === 2 ?
                    <RegisterFinal /> : <></>
                }
                {step >= 1 && step < 2 ?
                    <div className="d-grid gap-2">
                        <button type='button' className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }} onClick={next}>Weiter</button>
                    </div>
                    : <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }}>Registrieren</button>
                    </div>
                }
            </form>
            {step > 1 ? <div className="d-grid gap-2">
                <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#FFF", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
            </div> : <></>}
            {step === 0 && location.pathname.includes("register") ?
                <div className="d-grid gap-2">
                    <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
                </div> : <></>
            }
        </div>
    )
}

export default LandlordProfileMain